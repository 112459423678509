  import React, { Component } from 'react';
  import LOGO from './image/SCLOGO1.png';
  import './App.css';
  import {
    Stitch,
    RemoteMongoClient,
    AnonymousCredential
} from 'mongodb-stitch-browser-sdk';

const myClient = Stitch.initializeDefaultAppClient('siliconcanvas-tczcz');
  class App extends Component {
    constructor(props) {
      super(props)
      this.state = {
        client: myClient,
        m: window.matchMedia("(min-width: 700px)").matches,
        INTERVAL: 3000,
        PAGE: null, 
        whichPage : ["BASE", true],
        LEFTCONTENT : null,
        HOME : React.createRef(),
        MIDDLECONTENT: null,
        ABOUT : React.createRef(),
        CONTACTCONTENT : null,
        CONTACT : React.createRef(),
        SERVICECONTENT : null,
        SERVICE : React.createRef()
      }

      this.flashMenu = this.flashMenu.bind(this);
      this.HOME = this.HOME.bind(this);
      this.menu = this.menu.bind(this);
      this.closeMenus = this.closeMenus.bind(this);
      this.sendContactForm = this.sendContactForm.bind(this);
      this.hp = false;
      this.ap = false;
      this.cp = false;
      this.sp = false;
      this.op = false;
      this.pp = false;
    }


    HOME(a,b) {

      switch(a + "_" + b){

        case "R1LR_SR":
        //HOME
        if(this.state.whichPage[1] === false && this.hp){
        
            this.closeMenus(["R1LR", "R1MR", "R1CR", "R1SR"], ["HP", "AP", "CP", "SP"])
            this.hp = !this.hp
        }else{
          this.setState({
            LEFTCONTENT : React.cloneElement(
              <div ref={this.state.HOME} key="HOMECONTENT" className="col-md-12" id="HP">
                <h4 className="display-4">What the name implies</h4>
                <div className="row">
                  <div className="col-md-8 offset-md-4">
                  <p>A blank canvas.  Always trying new things.</p>

                  </div>
                </div>
              </div>
            ),
          }, () =>{
            this.hp = !this.hp

            this.closeMenus(["R1MR", "R1CR", "R1SR"], ["AP", "CP", "SP"])
    
            this.menu(a,b, this.state.HOME)

            var ms = document.getElementsByClassName("B");

            for(let s = 0; s< ms.length; s++){
              setTimeout(() => {
                document.getElementById("HP").appendChild(ms[s])
              }, s * 10);
            }
          })
        }
        break;

        case "R1MR_SR":
        //ABOUT
        if(this.state.whichPage[1] === false && this.ap){
        
            this.closeMenus(["R1LR", "R1MR", "R1CR", "R1SR"], ["HP", "AP", "CP", "SP"])
            this.ap = !this.ap;
      }else{
        this.setState({
          MIDDLECONTENT : React.cloneElement(
            <div ref={this.state.ABOUT} key="ABOUTCONTENT" className="col-md-12" id="AP">
              <h4 className="display-4">About Silicon Canvas</h4>
              <div className="row">
                <div className="col-md-6">
                  <p>
                  The name Silicon Canvas represents the virtually limitless world of digital art.  Here you will find meticulously crafted code that is written to match the imaginations of our clients.  We believe in bringing a little of the extraordinary into the mundane.                  </p>
                  <p>
                    Silicon Canvas is a one man show where clients are taken on one at a time and outside help is brought in when needed.  
                  </p>
                  <p>
                    Send us a message <u className="ucl"  onClick={() =>{
                this.setState({
                  whichPage : ["CONTACT", !this.state.whichPage[1]]
                },() => {
                  this.HOME("R1CR", "SR");

                })
              }}>using our contact form</u>
                  </p>
                  </div>
                  <div className="col-md-6 ls">
                  <p>
                    Here you can find:
                  </p>
                  <ul>
                    <li>Inspiring, hand coded websites to suit your specific needs.</li>
                    <br/>
                    <li>A well trained aversion to plugins and libraries.</li>
                    <br/>
                    <li>Freedom to choose between a backend programmed in <b>Java</b>, <b>NodeJS</b>, or <b>Python</b></li>
                    <br/>
                    <li>Options for web, desktop, and mobile applications at negotiable prices.</li>
                  </ul>
                </div>
              </div>
            </div>
          ),
        }, () =>{
          this.ap = !this.ap;

          this.closeMenus(["R1LR", "R1CR", "R1SR"], ["HP", "CP", "SP"])
  
          this.menu(a,b, this.state.ABOUT)

          var ms = document.getElementsByClassName("B");

          for(let s = 0; s< ms.length; s++){
            setTimeout(() => {
              document.getElementById("AP").appendChild(ms[s])
            }, s * 10);
          }
        })
      }
        break;

        case "R1CR_SR":
        //CONTACT
        if(this.state.whichPage[1] === false && this.cp){
        
          this.closeMenus(["R1LR", "R1MR", "R1CR", "R1SR"], ["HP", "AP", "CP", "SP"])
          this.cp = !this.cp;
        }else{
          this.setState({
            CONTACTCONTENT   : React.cloneElement(
              <div ref={this.state.CONTACT} key="CONTACTCONTENT" className="col-md-12" id="CP">
                <h4 className="display-4">CONTACT</h4>
                <div className="row" >
                  <div className="col-md-12">
                    <form className="form-control" onSubmit={(e) => {e.preventDefault(); this.sendContactForm();}}>
                      <input className="form-control n" required={true} placeholder="First Name" type="name" id="fn"  />
                      <input className="form-control n" type="name" required={true}  placeholder="Last Name" id="ln"  />
                      <input className="form-control n" type="email" required={true}  placeholder="Email" id="em"  />
                      <input className="form-control n"  type="phone" required={true}  placeholder="Phone" id="pn"  />
                      <textarea className="form-control" required={true}  id="m" placeholder="Type your message here." />
                      <button className="btn btn-outline-success btn-lg" type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            ),
          }, () =>{
            this.cp = !this.cp;
            this.closeMenus(["R1MR", "R1LR", "R1SR"], ["AP", "HP", "SP"])
            this.menu(a,b, this.state.CONTACT)

            var ms = document.getElementsByClassName("B");

            for(let s = 0; s< ms.length; s++){
              setTimeout(() => {
                document.getElementById("CP").appendChild(ms[s])
              }, s * 10);
            }
          })
        }
        break;

        case "R1SR_SR":
        //SERVICES
         if(this.state.whichPage[1] === false && this.sp){
        
          this.closeMenus(["R1LR", "R1MR", "R1CR", "R1SR"], ["HP", "AP", "CP", "SP"])
          this.sp = !this.sp;
        }else{
          this.setState({
            SERVICECONTENT   : React.cloneElement(
              <div ref={this.state.SERVICE} key="SERVICECONTENT" className="col-md-12" id="SP">
                <h2 className="display-4">SERVICES</h2>
                <div className="row" >
                  <div className="col-sm-4">
                  <div className="row sr1">
                      <div className="col-md-4">
                      <div className="MT">BASIC</div>
                      </div>
                      <div className="col-md-8">
                      <h4 className="display-4"><u>Desktop </u></h4><small>(Java &amp; JavaFX or C++ &amp; QT)</small>
                  <h1 className="display-2">$800</h1>
                      </div>

                   <div className="row">
                     <div className="col-md-12">
                       <p>Polished desktop programs written in Java that will work on nearly any device.  Functionality options include:</p>
                       <ul>
                         <li>Robust error handling and graceful fallbacks</li>
                         <li>Efficient code that makes use of tested design paradigms</li>
                         <li>Custom manuals and code explanations that explain how your program works</li>
                       </ul>
                     </div>
                   </div>
                   </div>

                   <hr />
                   <div className="row sr2">
                      <div className="col-md-4">
                      <div className="MT2">MID</div>
                      </div>
                      <div className="col-md-8">
                  <h1 className="display-2">$1000</h1>
                      </div>
                      </div>

                   <div className="row">
                     <div className="col-md-12">
                       <ul>
                     <li>Custom web API that allow your desktop application to connect to the internet</li>
                       </ul>
                     </div>
                   </div>

                   <hr />


                   <div className="row sr3">
      <div className="col-md-4">
      <div className="MT3">COMPLETE</div>
      </div>
      <div className="col-md-8">
  <h1 className="display-2">$1200</h1>
      </div>
      </div>

   <div className="row">
     <div className="col-md-12">
       <ul>
     <li>Custom web API</li>
     <li>Web front end included</li>
         <li>User metrics</li>
         <li>Robust error handling and graceful fallbacks</li>
       </ul>
     </div>
   </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="row sr1">
                      <div className="col-md-4">
                      <div className="MT">BASIC</div>
                      </div>
                      <div className="col-md-8">
                      <h4 className="display-4"><u>Web</u> </h4><small>(NodeJS, Java, or Python)</small>
                  <h1 className="display-2">$400</h1>
                      </div>
                      <div className="row">
                     <div className="col-md-12">
                       <ul>
                         <li>Static front end design</li>
                     <li>Free banner image design and/or photography</li>
                        
                       </ul>
                     </div>
                   </div> 
                   </div>          

                    <hr />

                   <div className="row sr2">
                      <div className="col-md-4">
                      <div className="MT2">MID</div>
                      </div>
                      <div className="col-md-8">
                  <h1 className="display-2">$600</h1>
                      </div>
                      </div>   
                      <div className="row">
                     <div className="col-md-12">
                       <ul>
                       <li>Wordpress or Javascript backend</li>
                     <li>Custom web API</li>

                       </ul>
                     </div>
                   </div>     

                   <hr />

                    <div className="row sr3">
      <div className="col-md-4">
      <div className="MT3">COMPLETE</div>
      </div>
      <div className="col-md-8">
  <h1 className="display-2">$800</h1>
      </div>
      </div>   
      <div className="row">
     <div className="col-md-12">
       <ul>
       <li>Wordpress or Javascript backend</li>
     <li>Custom web API</li>
         <li>User metrics</li>
         <li>Forums, comment sections, &amp; other requests included</li>
       </ul>
     </div>
   </div>                  
                   </div>
                   <div className="col-md-4">
                   <div className="row sr1">
                      <div className="col-md-4">
                      <div className="MT">BASIC</div>
                      </div>
                      <div className="col-md-8">
                      <h4 className="display-4"><u>Mobile</u></h4><small>(React Native or Java)</small>
                  <h1 className="display-2">$600</h1>
                  </div>

                  <div className="row">
                     <div className="col-md-12">
                       <ul>
                         <li>Basic mobile apps will be written in React Native (JavaScript) and will run on both Android and IOS</li>
                     <li>For Java applications (Android only), $600 will include logo design and branding kit</li>
                        
                       </ul>
                     </div>
                   </div>   
                      </div>

                      <hr />

                      <div className="row sr2">
                      <div className="col-md-4">
                      <div className="MT2">MID</div>
                      </div>
                      <div className="col-md-8">
                  <h1 className="display-2">$800</h1>
                  </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                       <ul>
                       <li>Custom web API</li>
                       <li>Logo design &amp; Branding Kit included</li>
                        
                       </ul>
                   </div>   
                      </div>


                   <hr />
                <div className="row sr3">
      <div className="col-md-4">
      <div className="MT3">COMPLETE</div>
      </div>
      <div className="col-md-8">
  <h1 className="display-2">$1000</h1>
  <div className="row">
     <div className="col-md-12">
       <ul>
       <li>Custom web API</li>
       <li>Web front end design</li>
         <li>User metrics</li>
       </ul>
     </div>
   </div>               

      </div>
      </div>
                </div>

               
                </div>
               
</div>
            ),
          }, () =>{
            this.sp = !this.sp;
            this.closeMenus(["R1MR", "R1LR", "R1CR"], ["AP", "HP", "CP"])
            this.menu(a,b, this.state.SERVICE)

            var ms = document.getElementsByClassName("B");

            for(let s = 0; s< ms.length; s++){
              setTimeout(() => {
                document.getElementById("SP").appendChild(ms[s])
              }, s * 10);
            }
          })
        }

        break;
        default:

        
            // this.closeMenus(["R1LR", "R1MR", "R1CR", "R1SR"], ["HP", "AP", "CP", "SP"])
            // this.setState({
            //   whichPage : ["BASE", true]
            // })
   
          break;
      }
      


    }


    sendContactForm(){
      this.state.client.auth.loginWithCredential(new AnonymousCredential()).then(user =>{
      this.state.client.callFunction("contact",[document.getElementById("em").value, document.getElementById("pn").value, [document.getElementById("fn").value, document.getElementById("ln").value], document.getElementById("m").value]).then(() => {
        alert("Your massage has been sent.")
      }).catch((err) => {
        alert(err)
      })
  })

}

    menu(a,b,c){
      setTimeout(() => {
        c.current.classList.add("o")
        c.current.parentNode.parentNode.classList.add("top")
      }, 1000);
      document.getElementById(a).classList.add(b)

      var ms = document.getElementsByClassName("B");

        for (let k = 0; k < ms.length; k++) {


          setTimeout((k) => {
            // !ms[k].classList.contains("flash") ?  ms[k].classList.add("flash") : ms[k].classList.remove("flash");
            document.getElementById(a).appendChild(ms[k])

          }, k * 10, [k])
        }
      
    }
    
    closeMenus(a,b){

      var ms = document.getElementsByClassName("B");

      for(let j = 0; j < a.length; j++){
      if(document.getElementById(b[j]) !== null){
    document.getElementById(b[j]).classList.remove("o")
    document.getElementById(b[j]).parentNode.parentNode.classList.remove("top")

      }
         for (let k = 0; k < ms.length; k++) {

          setTimeout((k) => {
            !ms[k].classList.contains("flash") ? ms[k].classList.add("flash") : ms[k].classList.remove("flash");
            document.getElementById("BB").appendChild(ms[k])
    
          }, k * 10, [k])
        }
        this.hp = !this.hp;
        document.getElementById(a[j]).classList.remove("SR")
      }

    }


    componentDidMount() {   

      

      this.setState({
        blocks: new Array(100).fill(0).map((x, index) => {
          return React.cloneElement(
            <div key={"B" + index} className="B"></div>
          )
        })
      }, () => {
          this.state.client.callFunction("tick");

        this.flashMenu("x", true, null)
      })
    }

    flashMenu(_which, b, num) {
      var ms = document.getElementsByClassName("B");



      if (_which === "x") {
        for (let k = 0; k < ms.length; k++) {
          let r = Math.round(Math.random() * 99)
          console.log(r)
          setTimeout((k) => {
            !ms[r].classList.contains("flash") ? ms[r].classList.add("flash") : ms[r].classList.remove("flash")
          }, k * 10, [r])
        }
      } else {

        switch (_which) {
          case 0:
            if (b) {

              for (let k = num - 7; k < num; k++) {

                setTimeout(function () {
                  ms[k].classList.add("slow")

                  ms[k].classList.remove("flash")
                  ms[k].classList.remove("slow")

                  ms[k + 50].classList.add("slow")

                  ms[k + 50].classList.remove("flash")
                  ms[k + 50].classList.remove("slow")

                }, k * 25, [ms, k])

              }
            } else {

              for (let k = num - 7; k < num; k++) {

                setTimeout(function () {
                  let r = Math.round(Math.random() * num) + num - 7
                  let s = Math.round(Math.random() * num) + num - 7
                  ms[r].classList.add("slow")

                  ms[r].classList.add("flash")
                  ms[r].classList.remove("slow")

                  ms[s + 50].classList.add("slow")

                  ms[s + 50].classList.add("flash")
                  ms[s + 50].classList.remove("slow")

                }, k * 25, [ms, k])

              }
            }
            break;

          default:
            if (b) {

              for (let k = num; k < num + num; k++) {

                setTimeout(function () {
                  ms[k].classList.add("slow")

                  ms[k].classList.remove("flash")
                  ms[k].classList.remove("slow")

                  ms[(k + 50) < 99 ? k + 50 : 99].classList.add("slow")

                  ms[(k + 50) < 99 ? k + 50 : 99].classList.remove("flash")
                  ms[(k + 50) < 99 ? k + 50 : 99].classList.remove("slow")

                }, k * 25, [ms, k])

              }
            } else {

              for (let k = num; k < num + num; k++) {

                setTimeout(function () {
                  let r = Math.round(Math.random() * num) + _which * 7
                  let s = Math.round(Math.random() * num) + _which * 7
                  ms[r].classList.add("slow")

                  ms[r].classList.add("flash")
                  ms[r].classList.remove("slow")

                  ms[(s + 50) < 99 ? s + 50 : 99].classList.add("slow")

                  ms[(s + 50) < 99 ? s + 50 : 99].classList.add("flash")
                  ms[(s + 50) < 99 ? s + 50 : 99].classList.remove("slow")

                }, k * 25, [ms, k])

              }
            }

            break;
        }
      }




    }


    render() {
      return (
        <div className="container-fluid" id="APP">
          {/* <div id="LG" className="row">
            <div className="col-2 offset-md-5">
            <img src={LOGO} alt="SILICON CANVAS" id="LOGO" />
            </div>
          </div> */}
          <div className="row" id="R0">
            <div id="BB" >
              {this.state.blocks}
            </div>

            <div className="col-2 mm">
              <bdi onMouseEnter={() => { this.flashMenu(0, true, 7) }} onMouseLeave={() => { this.flashMenu(0, false, 7) }} onClick={() => {

                this.setState({
                  whichPage : ["HOME", !this.state.whichPage[1]]
                },() => {
                  this.HOME("R1LR", "SR");

                })
              }}>{(this.state.m) ? "Home" : <i className="fi-list"></i>}</bdi>
            </div>
            <div className="col-2 mm">
              <bdi onMouseEnter={() => { this.flashMenu(1, true, 8) }} onMouseLeave={() => { this.flashMenu(1, false, 8) }}
              onClick={() =>{
                this.setState({
                  whichPage : ["CONTACT", !this.state.whichPage[1]]
                },() => {
                  this.HOME("R1CR", "SR");

                })
              }}
              >{(this.state.m) ? "Contact" : <i className="fi-mail"></i>}</bdi>
            </div>
            <div className="col-2 mm">
              <bdi onClick={() => {
                this.setState({
                  whichPage : ["ABOUT", !this.state.whichPage[1]]
                },() => {
                this.HOME("R1MR", "SR");
                })            }} onMouseEnter={() => { this.flashMenu(2, true, 14) }} onMouseLeave={() => { this.flashMenu(2, false, 14) }}>{(this.state.m) ? "About" : <i className="fi-comment-quotes"></i>}</bdi>
            </div>
            <div className="col-2 mm">
              <bdi onClick={() => {
                this.setState({
                  whichPage : ["SERVICES", !this.state.whichPage[1]]
                },() => {
                this.HOME("R1SR", "SR");
                })            }} onMouseEnter={() => { this.flashMenu(3, true, 18) }} onMouseLeave={() => { this.flashMenu(3, false, 18) }}>{(this.state.m) ? "Services" : <i className="fi-bookmark"></i>}</bdi>
            </div>
            <div className="col-2 mm">
              <bdi onMouseEnter={() => { this.flashMenu(4, true, 24) }} onMouseLeave={() => { this.flashMenu(4, false, 24) }}>{(this.state.m) ? "Social" : <i className="fi-share"></i>}</bdi>
            </div>
            <div className="col-2 mm">
              <bdi onMouseEnter={() => { this.flashMenu(5, true, 30) }} onMouseLeave={() => { this.flashMenu(5, false, 30) }}>{(this.state.m) ? "Projects" : <i className="fi-folder-add"></i>}</bdi>
            </div>
          </div>
          <div   className="row d-flex" id="R1">
            <div className="col-md-4" id="R1L">
              <div className="row v" id="R1LR">
                {this.state.LEFTCONTENT}
              </div>

            </div>
            <div className="col-md-6 offset-md-2" id="R1C">
              <div className="row v" id="R1CR">
                {this.state.CONTACTCONTENT}
              </div>

            </div>
            <div className="col-md-8 offset-md-4" id="R1M">
            <div className="row v" id="R1MR">
                {this.state.MIDDLECONTENT}
            </div>

            </div>

            <div className="col-md-12" id="R1S">
              <div className="row v" id="R1SR">
                {this.state.SERVICECONTENT}
              </div>

            </div>
          
          </div>
        
        </div>
      );
    }
  }

  export default App;
